import {
  CHAR_ARR_INTEGRATION,
  UNSIGNED_INTEGER_INTEGRATION,
  SIGNED_INTEGER_INTEGRATION,
  HALF_PREC_FLOAT_INTEGRATION,
  SINGLE_PREC_FLOAT_INTEGRATION,
  SINGLE_BYTE_FLOAT_INTEGRATION,
  VALUE_MAP_INTEGRATION,
  BIT_MASK_INTEGRATION,
  RAW_BYTE_INTEGRATION,
  FILE_INTEGRATION,
  BOOLEAN_INTEGRATION,
  IDENTIFIER_INTEGRATION,
} from '@lunar-outpost/zap/lib/types';

import { v4 as uuidv4 } from 'uuid';

export type Integration =
  | CHAR_ARR_INTEGRATION
  | UNSIGNED_INTEGER_INTEGRATION
  | SIGNED_INTEGER_INTEGRATION
  | HALF_PREC_FLOAT_INTEGRATION
  | SINGLE_PREC_FLOAT_INTEGRATION
  | SINGLE_BYTE_FLOAT_INTEGRATION
  | VALUE_MAP_INTEGRATION
  | BIT_MASK_INTEGRATION
  | RAW_BYTE_INTEGRATION
  | FILE_INTEGRATION
  | IDENTIFIER_INTEGRATION;

export interface ValueProps {
  id: string;
  label: string;
}

export interface SelectorProps {
  value: ValueProps | null;
  index?: number;
}

export interface UpdateIntegrationProps {
  integration?: Integration;
  prefixEnabled?: boolean;
}

export interface IntegrationBuilderProps {
  integration: Integration;
  updateIntegration: (props: UpdateIntegrationProps) => void;
  identifierOptions: ValueProps[];
  refetchIdentifiers: () => void;
  isIdentifierPrefixEnabled: boolean;
}

export interface IdentifierSelectorProps extends SelectorProps {
  handleIdentifierSelect: (props: SelectorProps) => void;
  options: ValueProps[];
}

export interface ZapMessage {
  ZapMessageId: string;
  FriendlyName: string;
  MessageClass: string;
  Notes: string;
  Priority: string;
  SubSystem: string;
  SubType: string;
  System: string;
  Type: string;
}

export interface Definition {
  ByteCount: number;
  IntegrationType: string;
  Integration: Integration;
  Label: string;
  UseResourcePrefix?: boolean;
}

export interface DefaultIntegrations {
  CHAR_ARR: CHAR_ARR_INTEGRATION;
  BOOLEAN: BOOLEAN_INTEGRATION;
  HALF_PREC_FLOAT: HALF_PREC_FLOAT_INTEGRATION;
  SIGNED_INTEGER: SIGNED_INTEGER_INTEGRATION;
  SINGLE_BYTE_FLOAT: SINGLE_BYTE_FLOAT_INTEGRATION;
  SINGLE_PREC_FLOAT: SINGLE_PREC_FLOAT_INTEGRATION;
  UNSIGNED_INTEGER: UNSIGNED_INTEGER_INTEGRATION;
  FILE: FILE_INTEGRATION;
  VALUE_MAP: VALUE_MAP_INTEGRATION;
  BIT_MASK: BIT_MASK_INTEGRATION;
  IDENTIFIER: IDENTIFIER_INTEGRATION;
}

export const FILE_TYPES = {
  TGZ: 'tgz',
  CSV: 'csv',
  PNG: 'png',
  LOG: 'log',
  LVX: 'lvx',
};

export interface Blocks {
  [key: string]: Definition;
}

const exampleLayout = [{ x: 0, y: 0, w: 3, h: 3, i: uuidv4(), minW: 12 }];
export type Layout = typeof exampleLayout;

const exampleIdentifier = { id: '738a2c69-5dc9-4256-b242-42c86212dc83', label: 'EKF_DURATION' };
export type Identifier = typeof exampleIdentifier;
