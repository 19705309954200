import { useCallback, useState, useEffect } from 'react';

import { DataGrid, GridRowsProp, GridColumns, GridActionsCellItem, GridRowModel, GridRowId, GridToolbarContainer } from '@mui/x-data-grid';

import { Button } from '@mui/material';

import RemoveIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import { randomId } from '@mui/x-data-grid-generator';

import { toast } from 'react-toastify';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows } = props;

  const handleKeyAdd = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, key: '', value: '' }]);
  };

  return (
    <GridToolbarContainer>
      <Button color="secondary" variant="outlined" size="small" startIcon={<AddIcon />} onClick={handleKeyAdd} id="entry-button">
        Add Key/Value
      </Button>
    </GridToolbarContainer>
  );
}

interface ValueMapTableProps {
  pairs: Record<string, string>;
  updatePairs: (pairs: Record<string, string>) => void;
}
const ValueMapTable = ({ pairs, updatePairs }: ValueMapTableProps) => {
  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    const integrationRows: GridRowsProp = Object.keys(pairs).map((key, i) => {
      return {
        id: i,
        key,
        value: pairs[key],
      };
    });
    setRows(integrationRows);
  }, [pairs]);

  const handleDeleteClick = (id: GridRowId) => () => {
    const { [rows[id as number].key]: deletePair, ...remainingPairs } = pairs;
    updatePairs(remainingPairs);
  };

  const columns: GridColumns = [
    {
      field: 'key',
      headerName: 'Key',
      editable: true,
    },
    { field: 'value', headerName: 'Value', editable: true, flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [<GridActionsCellItem icon={<RemoveIcon />} label="Remove" onClick={handleDeleteClick(id)} color="error" />];
      },
    },
  ];

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      console.log(newRow);
      const re = /^[0-9A-F]{2}$/;
      const key = newRow.key;
      const reTest = re.test(key);
      let hasError = !reTest;
      if (hasError) {
        toast.error('Could not update key. \nKey must follow pattern: [0-9A-F]');
        return;
      }
      for (const row of rows) {
        if (row.key === key && row.id !== newRow.id) {
          hasError = true;
          toast.error('Could not update key. \nKey must be unique');
          return;
        }
      }

      const pairsCopy = JSON.parse(JSON.stringify(pairs));
      for (const row of rows) {
        if (row.id === newRow.id && row.key !== newRow.key) {
          delete pairsCopy[row.key];
        }
      }
      const newPairs = { ...pairsCopy, [newRow.key]: newRow.value };
      updatePairs(newPairs);
      return newRow;
    },
    [pairs, rows, updatePairs]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {}, []);

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        density={'compact'}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'key', sort: 'asc' }],
          },
        }}
      />
    </div>
  );
};

export default ValueMapTable;
