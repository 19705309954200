import { forwardRef } from 'react';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';

import { Box, IconButton, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import { NavLink, LinkProps } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MessagesIcon from '@mui/icons-material/SatelliteAlt';
import IdentifierIcon from '@mui/icons-material/TextFormat';

import DrawerHeader from '../DrawerHeader';

const data = [
  { name: 'Messages', icon: <MessagesIcon />, link: 'messages' },
  { name: 'Identifiers', icon: <IdentifierIcon />, link: 'identifiers' }
]

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface SidebarProps {
  open: boolean;
  handleClose: () => void;
}

const Sidebar = ({ open, handleClose }: SidebarProps) => {
  const theme = useTheme();

  let activeStyle = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    textDecoration: 'none',
  };

  let mainStyle = {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    underline: 'false',
  };

  const ReactNav = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <NavLink ref={ref} {...props} style={({ isActive }) => (isActive ? activeStyle : mainStyle)} />
  ));

  const getList = () => (
    <Box style={{ width: '100%' }} onClick={() => handleClose()}>
      {data.map((item, index) => (
        <ListItemButton key={`${item.name}-${index}`} to={item.link} component={ReactNav}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
    </Box>
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
      </DrawerHeader>
      {getList()}
    </Drawer>
  );
};

export default Sidebar;
