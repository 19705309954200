import { useState, useEffect } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useParams } from 'react-router-dom';
import './styles.css';

import { useTheme } from '@mui/material';
import { Box, Stack, Paper, Typography, Breadcrumbs, Link } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

import PortalWrapper from 'Components/PortalWrapper';
import { gql, useMutation, useLazyQuery } from '@apollo/client';

import { toast } from 'react-toastify';

import { messagesUrl } from 'utilities/navigation';

import ZAPWrapper from './ZAPRendering/ZAPWrapper';
import IntegrationEditor from './IntegrationEditor';
import InfoHeader from './InfoHeader';
import ButtonHeader from './ButtonHeader';

import { Definition, Blocks, Integration, Layout, ZapMessage, DefaultIntegrations } from './types';
import { BIT_MASK_INTEGRATION, INTEGRATION_TYPES } from '@lunar-outpost/zap/lib/types';
import { UPDATE_ZAP_DEFINITION } from 'api/queries';

const ReactGridLayout = WidthProvider(RGL);

const singleResourceIntegration: Integration = {
  resource: {
    resourceName: '',
  },
};

const fileResourceIntegration: Integration = {
  resource: {
    fileExtension: '',
  },
};

const defaultIntegrationsByIntegrationType: DefaultIntegrations = {
  CHAR_ARR: singleResourceIntegration,
  BOOLEAN: singleResourceIntegration,
  FILE: fileResourceIntegration,
  HALF_PREC_FLOAT: singleResourceIntegration,
  SIGNED_INTEGER: singleResourceIntegration,
  SINGLE_PREC_FLOAT: singleResourceIntegration,
  UNSIGNED_INTEGER: singleResourceIntegration,
  SINGLE_BYTE_FLOAT: { ...singleResourceIntegration, offset: 0 },
  IDENTIFIER: { resourcePrefixMap: { '00': 'EXAMPLE VALUE' } },
  VALUE_MAP: {
    resource: {
      resourceName: '',
    },
    map: {
      '00': 'EXAMPLE VALUE',
    },
  },
  BIT_MASK: {
    resources: [],
  },
};

const MessageDefinitions = () => {
  const [layout, setLayout] = useState<Layout>([]);
  const [blocks, setBlocks] = useState<Blocks>({});

  const [activeBlock, setActiveBlock] = useState('');
  const [zapMessage, setZapMessage] = useState<ZapMessage>({} as ZapMessage);

  const [loading, setLoading] = useState(true);
  const [blocksChanged, setBlocksChanged] = useState(false);

  const { zapMessageId } = useParams();

  const theme = useTheme();

  const [updateZapMessageDefinition] = useMutation(UPDATE_ZAP_DEFINITION);

  /**
   * Coerce ByteCount field into mathematical bounds (e.g. SINGLE_PREC_FLOAT is 32-bit, can only be 4 bytes long)
   * and overwrite the entered ByteCount with the maximum or minimum permitted value
   */
  const getByteCountBoundedByType = (newBlock: Definition): number => {
    const { ByteCount } = newBlock;

    switch (newBlock.IntegrationType) {
      case 'UNSIGNED_INTEGER':
      case 'SIGNED_INTEGER':
        return Math.min(Math.max(1, ByteCount), 8);
      case 'SINGLE_PREC_FLOAT':
        return 4;
      case 'HALF_PREC_FLOAT':
        return 2;
      case 'IDENTIFIER':
      case 'SINGLE_BYTE_FLOAT':
      case 'BOOLEAN':
        return 1;
      case 'FILE':
        return -1;
      default:
        return Math.max(1, ByteCount);
    }
  };

  const addNewItem = () => {
    const blockId = uuidv4();
    const newY = layout.length > 0 ? layout[layout.length - 1].y + 3 : 0;
    const newItem = { x: 0, y: newY, w: 12, h: 4, i: blockId, minW: 12, minH: 4, maxH: 4 };

    if (layout.some((item) => item.x === 0 && item.y === 0)) {
      setLayout(
        layout
          .map((item) => {
            if (item.x === 0) {
              return { ...item, y: item.y-- };
            }

            return item;
          })
          .concat([newItem])
          .sort((a, b) => (a.y > b.y ? 1 : -1))
      );
    } else {
      setActiveBlock(blockId);
      setLayout(layout.concat([newItem]).sort((a, b) => (a.y > b.y ? 1 : -1)));
    }

    setBlocksChanged(true);
    setBlocks({
      ...blocks,
      [blockId]: {
        ByteCount: 4,
        IntegrationType: INTEGRATION_TYPES.CHAR_ARR,
        Integration: singleResourceIntegration,
        Label: '',
      },
    });
  };

  let queryString = 'query getMessageDefinitionData ($roverId: String!, $zapMessageId: String!) {\n';
  queryString += `
    getMessage(RoverId: $roverId, ZapMessageId: $zapMessageId ) {
      ZapMessageId
      System
      SubSystem
      Priority
      MessageClass
      Type
      SubType
      FriendlyName
      Notes
    }
    `;
  queryString += `
    getZapMessageDefinition(RoverId: $roverId, ZapMessageId: $zapMessageId ) {
      Definition {
          ByteCount
          IntegrationType
          Integration
          Label
          UseResourcePrefix
      }
      Layout {
          w
          h
          x
          y
          i
          minW
          minH
          maxH
          moved
          static
      }
    } 
    `;
  queryString += '}';

  const [getZapMessageData] = useLazyQuery(
    gql`
      ${queryString}
    `,
    {
      onCompleted: (result) => {
        const { __typename, ...zapMessage } = result.getMessage;
        setZapMessage(zapMessage);
        const { Definition, Layout } = result.getZapMessageDefinition;
        if (Definition.length !== 0) {
          const blocks: Blocks = {};
          for (let i = 0; i < Definition.length; i++) {
            const { __typename, ...currentDefinition } = Definition[i];
            currentDefinition.Integration = JSON.parse(currentDefinition.Integration);
            blocks[Layout[i].i] = currentDefinition;
          }

          setBlocks(blocks);
          setLayout(Layout);
          setActiveBlock(Layout[0].i);
        } else {
          setBlocks({});
          setLayout([]);
        }

        setBlocksChanged(false);
        setLoading(false);
      },
      variables: {
        roverId: '1',
        zapMessageId: zapMessageId,
      },
    }
  );

  useEffect(() => {
    getZapMessageData();
  }, [getZapMessageData]);

  const updateBlock = (blockId: string, newBlock: Definition) => {
    if (blocks[blockId].IntegrationType !== newBlock.IntegrationType) {
      newBlock.Integration = defaultIntegrationsByIntegrationType[newBlock.IntegrationType as keyof DefaultIntegrations];
      newBlock.ByteCount = getByteCountBoundedByType(newBlock);

      if (newBlock.IntegrationType === INTEGRATION_TYPES.BIT_MASK) {
        const integrationRef = newBlock.Integration as BIT_MASK_INTEGRATION;
        const resources = integrationRef.resources;
        for (let i = 0; i < newBlock.ByteCount * 8; i++) {
          resources.push({ resourceName: '' });
        }
      }
    }

    setBlocksChanged(true);
    setBlocks({
      ...blocks,
      [blockId]: newBlock,
    });
    setActiveBlock(blockId);
  };

  const saveMessageDetails = async () => {
    const blockToSave = JSON.parse(JSON.stringify(blocks));
    let updatesToStore = [];
    for (const row of layout) {
      blockToSave[row.i].Integration = JSON.stringify(blocks[row.i].Integration);
      updatesToStore.push(blockToSave[row.i]);
    }

    setLoading(true);
    const result = await updateZapMessageDefinition({
      variables: {
        roverId: '1',
        zapMessageDefinition: {
          ZapMessageId: zapMessage.ZapMessageId,
          Definition: updatesToStore,
          Layout: layout,
        },
      },
    });

    const success = result.data.updateZapMessageDefinition.success;
    if (success) {
      toast.success('Succesfully saved ZAP Message Definition!');
    } else {
      toast.error('Could not save ZAP Message Definition');
    }
    setLoading(false);
  };

  const handleBlockClick = (id: string) => {
    setActiveBlock(id);
  };

  const deleteBlockHandler = (id: string) => {
    const blocksCopy = JSON.parse(JSON.stringify(blocks));
    const { [id]: removedBlock, ...remainingBlocks } = blocksCopy;

    const filteredLayout = layout.filter((value) => {
      return value.i !== id;
    });
    setLayout(filteredLayout);
    setBlocksChanged(true);
    setBlocks(remainingBlocks);
  };

  const breadcrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href={messagesUrl()}>
        Messages
      </Link>
      <Typography color="text.primary">Definitions</Typography>
      <Typography color="text.primary">{zapMessage.FriendlyName}</Typography>
    </Breadcrumbs>
  );

  return (
    <PortalWrapper breadcrumbs={breadcrumbs} loading={loading}>
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <InfoHeader messageInfo={zapMessage} />
        <ButtonHeader addNewItem={addNewItem} saveMessageDetails={saveMessageDetails} resetData={getZapMessageData} blocksChanged={blocksChanged} />
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          {layout.length > 0 ? (
            <>
              <Box
                sx={{
                  maxWidth: '50vw',
                  minWidth: '50vw',
                  maxHeight: '75vh',
                  overflowY: 'auto',
                  overflowX: 'auto',
                }}
              >
                <ReactGridLayout
                  isDraggable={true}
                  rowHeight={10}
                  preventCollision={false}
                  cols={12}
                  onLayoutChange={(layout: any) => setLayout(layout.sort((a: any, b: any) => (a.y > b.y ? 1 : -1)))}
                >
                  {layout.map((item, index) => (
                    <div
                      key={item.i}
                      data-grid={item}
                      style={{
                        width: '100%',
                        border: activeBlock === item.i ? `2px solid ${theme.palette.secondary.main}` : '',
                      }}
                    >
                      <ZAPWrapper
                        blockIndex={index}
                        blockId={item.i}
                        block={blocks[item.i]}
                        updateBlock={updateBlock}
                        handleBlockClick={handleBlockClick}
                        deleteBlockHandler={deleteBlockHandler}
                      ></ZAPWrapper>
                    </div>
                  ))}
                </ReactGridLayout>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: '40vw',
                    height: '40vw',
                  },
                  maxHeight: '75vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                <Paper>
                  <IntegrationEditor blockId={activeBlock} block={blocks[activeBlock]} updateBlock={updateBlock} />
                </Paper>
              </Box>
            </>
          ) : (
            <Typography>Message does not have a definition. Click "ADD ITEM" to begin.</Typography>
          )}
        </Stack>
      </Stack>
    </PortalWrapper>
  );
};

export default MessageDefinitions;
