import { styled } from '@mui/material/styles';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { Box, Toolbar, Typography, IconButton, Stack } from '@mui/material';

import AccountMenu from './AccountMenu';

import MenuIcon from '@mui/icons-material/Menu';

import { AmplifyUser, AuthEventData } from '@aws-amplify/ui';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface LayoutProps {
  signOut?: (data?: AuthEventData | undefined) => void;
  open: boolean;
  handleOpen: () => void;
  user?: AmplifyUser;
  isDarkMode: boolean;
  setIsDarkMode: (selection: boolean) => void;
}

const Topbar = ({ signOut, open, handleOpen, user }: LayoutProps) => {
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} style={{ width: '100%' }}>
          <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <IconButton
              color="inherit"
              onClick={handleOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography variant="h6" noWrap component="div">
                Zebchat Coordination Portal
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Typography variant="h6" noWrap component="div">
                ZCP
              </Typography>
            </Box>
          </Box>
          <AccountMenu signOut={signOut} user={user} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
