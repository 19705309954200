import { useEffect, useState } from 'react';

import { Definition, UpdateIntegrationProps, ValueProps } from '../types';
import { INTEGRATION_TYPES } from '@lunar-outpost/zap/lib/types';

import { useLazyQuery } from '@apollo/client';

import CHAR_ARR from './CHAR_ARR';
import BOOLEAN from './BOOLEAN';
import HALF_PREC_FLOAT from './HALF_PREC_FLOAT';
import SIGNED_INTEGER from './SIGNED_INTEGER';
import SINGLE_BYTE_FLOAT from './SINGLE_BYTE_FLOAT';
import SINGLE_PREC_FLOAT from './SINGLE_PREC_FLOAT';
import UNSIGNED_INTEGER from './UNSIGNED_INTEGER';
import VALUE_MAP from './VALUE_MAP';
import BIT_MASK from './BIT_MASK';
import FILE from './FILE';
import IDENTIFIER_FIELD from './IDENTIFIER_TYPE';
import { GET_ALL_IDENTIFIERS } from 'api/queries';
import LoadingSpinner from 'Components/LoadingSpinner';

interface IntegrationEditorProps {
  block: Definition;
  blockId: string;
  updateBlock: (blockId: string, newBlock: Definition) => void;
}

const IntegrationEditor = ({ blockId, block, updateBlock }: IntegrationEditorProps) => {
  const [searchSelectData, setSearchSelectData] = useState<ValueProps[]>([]);

  const [getAllIdentifiers] = useLazyQuery(GET_ALL_IDENTIFIERS, {
    onCompleted: (result) => {
      const searchSelectData = result.getAllIdentifiers.map((elem: any) => {
        return {
          id: elem.IdentifierId,
          label: elem.IdentifierName,
        };
      });
      const resourceMap = {} as { [key: string]: string };
      for (let i = 0; i < searchSelectData.length; i++) {
        resourceMap[searchSelectData[i].label] = searchSelectData[i].id;
      }
      setSearchSelectData(searchSelectData);
    },
    variables: {
      roverId: '1',
    },
  });

  useEffect(() => {
    getAllIdentifiers();
  }, [getAllIdentifiers]);

  const createEditor = () => {
    if (block === undefined || blockId === undefined) {
      return <> No Blocks Created </>;
    }

    // show loading spinner if searchSelectData is still empty
    if (searchSelectData === undefined || searchSelectData.length < 1) {
      // TODO: use actual statefulness to show/hide this loading spinner
      return <LoadingSpinner />;
    }

    const updateIntegration = ({ integration, prefixEnabled }: UpdateIntegrationProps) => {
      const newBlock: Definition = JSON.parse(JSON.stringify(block));
      if (integration !== undefined) {
        console.log(`Now updating Integration ${JSON.stringify(integration)} for blockId ${blockId}. Block: ${JSON.stringify(block)}`);
        newBlock.Integration = integration;
      }

      if (prefixEnabled !== undefined) {
        console.log(`Now setting UseResourcePrefix to ${prefixEnabled} for blockId ${blockId}. Block: ${JSON.stringify(block)}`);
        newBlock.UseResourcePrefix = prefixEnabled;
      }

      updateBlock(blockId, newBlock);
    };

    const integrationType = block.IntegrationType;
    const integration = block.Integration;
    const isIdentifierPrefixEnabled = block.UseResourcePrefix ?? false;

    switch (integrationType) {
      case INTEGRATION_TYPES.CHAR_ARR:
        return (
          <CHAR_ARR
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.BOOLEAN:
        return (
          <BOOLEAN
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.HALF_PREC_FLOAT:
        return (
          <HALF_PREC_FLOAT
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.SIGNED_INTEGER:
        return (
          <SIGNED_INTEGER
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.SINGLE_BYTE_FLOAT:
        return (
          <SINGLE_BYTE_FLOAT
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.SINGLE_PREC_FLOAT:
        return (
          <SINGLE_PREC_FLOAT
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.UNSIGNED_INTEGER:
        return (
          <UNSIGNED_INTEGER
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.VALUE_MAP:
        return (
          <VALUE_MAP
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
            isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
          />
        );
      case INTEGRATION_TYPES.FILE:
        return (
          <FILE
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
          />
        );
      case INTEGRATION_TYPES.BIT_MASK:
        return (
          <BIT_MASK
            integration={integration}
            updateIntegration={updateIntegration}
            identifierOptions={searchSelectData}
            refetchIdentifiers={getAllIdentifiers}
          />
        );
      case INTEGRATION_TYPES.IDENTIFIER:
        return <IDENTIFIER_FIELD integration={integration} updateIntegration={updateIntegration} />;
      default:
        return <>NOT_SET</>;
    }
  };

  return createEditor();
};

export default IntegrationEditor;
