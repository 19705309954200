import Ajv from 'ajv';
export const validator = new Ajv();

export const getValidatorErrors = () => {
  const errors =
    validator.errors !== null && validator.errors !== undefined
      ? `${validator.errors[0].instancePath.substring(1)}: ${validator.errors[0].message}`
      : '';
  return errors;
};

const screamingSnakeCaseRe = '^(?:[A-Z]|[0-9])+(?:_(?:[A-Z]|[0-9])+)*$';

const systemSchema = { type: 'string', minLength: 4, maxLength: 25, pattern: screamingSnakeCaseRe };
const typeSchema = { type: 'string', minLength: 4, maxLength: 4 };

export const identifierSchema = {
  properties: {
    id: { type: 'string' },
    IdentifierName: { type: 'string', minLength: 5, maxLength: 25, pattern: screamingSnakeCaseRe },
    SubSystem: systemSchema,
    Description: { type: 'string' },
  },
  required: ['id', 'IdentifierName', 'SubSystem'],
  additionalProperties: false,
};

export const messageSchema = {
  properties: {
    id: { type: 'string' },
    System: systemSchema,
    SubSystem: systemSchema,
    Priority: { type: 'string', enum: ['CRITICAL', 'QUALITY_OF_LIFE', 'NICE_TO_HAVE', 'OTHER'] },
    MessageClass: { type: 'string', enum: ['AE', 'DR', 'CS', 'OTHER'] },
    Type: typeSchema,
    SubType: typeSchema,
    FriendlyName: { type: 'string', minLength: 5, maxLength: 40 },
    MessageType: { type: 'string', enum: ['TELEMETRY', 'COMMANDS', 'INTERNAL'] },
    MessageReadiness: { type: 'string', enum: ['DEVELOPMENT', 'STAGING', 'PRODUCTION'] },
    Notes: { type: 'string' },
  },
  required: ['id', 'System', 'SubSystem', 'Priority', 'MessageClass', 'Type', 'SubType', 'FriendlyName'],
  additionalProperties: false,
};
