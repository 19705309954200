import { createTheme } from '@mui/material/styles';

const sharedThemeSettings = {
  typography: {
    fontSize: 15,
    h1: {},
    h2: {},
    h3: {},
    h4: {},
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF', // main color for app
    },
    secondary: {
      main: '#009EDB',
    },
    success: {
      main: '#23C65A',
    },
  },
  ...sharedThemeSettings,
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#16171B', // main color for app
    },
    secondary: {
      main: '#009EDB',
    },

    success: {
      main: '#23C65A',
    },
  },
  ...sharedThemeSettings,
});
