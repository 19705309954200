import { Box, Stack, Typography, Divider } from '@mui/material';
import { ZapMessage } from '../types';



interface InfoHeaderProps {
  messageInfo: ZapMessage;
}

const Header = ({  messageInfo }: InfoHeaderProps) => {
  const renderTextLabel = (label: string, value: string) => {
    return (
      <Typography component="div">
        <Box fontWeight="fontWeightBold" display="inline">
          {label}:
        </Box>{' '}
        {value}
      </Typography>
    );
  };

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      {renderTextLabel('System', messageInfo.System)}
      <Divider orientation="vertical" flexItem />

      {renderTextLabel('Subsystem', messageInfo.SubSystem)}
      <Divider orientation="vertical" flexItem />

      {renderTextLabel('Message Class', messageInfo.MessageClass)}
      <Divider orientation="vertical" flexItem />

      {renderTextLabel('Type', messageInfo.Type)}
      <Divider orientation="vertical" flexItem />

      {renderTextLabel('Subtype', messageInfo.SubType)}
      <Divider orientation="vertical" flexItem />
    </Stack>
  );
};

export default Header;
