import { Stack } from '@mui/material';

import { useMutation } from '@apollo/client';
import { CREATE_IDENTIFIER, DELETE_IDENTIFIER, GET_ALL_IDENTIFIERS, UPDATE_IDENTIFIER } from 'api/queries';

import PortalWrapper from 'Components/PortalWrapper';
import CrudTable from 'Components/CrudTable';
import { GridColumns } from '@mui/x-data-grid-pro';

import { identifierSchema, validator } from 'utilities/schemas';

const Identifiers = () => {
  const [createIdentifier] = useMutation(CREATE_IDENTIFIER);
  const [updateIdentifier] = useMutation(UPDATE_IDENTIFIER);
  const [deleteIdentifier] = useMutation(DELETE_IDENTIFIER);

  // When we get all the records back, how do we map these to rows?
  const onGetAllMapper = (elem: any) => {
    const { IdentifierId, __typename, ...restOfElem } = elem;
    return { ...restOfElem, id: elem.IdentifierId };
  };

  // handle new record creation
  const createIdentifierHandler = async (newEntity: Record<string, string>): Promise<string | undefined> => {
    const result = await createIdentifier({
      variables: {
        roverId: '1',
        identifier: {
          IdentifierName: newEntity.IdentifierName,
          SubSystem: newEntity.SubSystem,
          Description: newEntity.Description,
        },
      },
    });
    const success = result.data.createIdentifier !== undefined;
    if (success) {
      return result.data.createIdentifier.IdentifierId;
    }
    return undefined;
  };

  // handle update record
  const updateIdentifierHandler = async (updateEntity: Record<string, string>): Promise<string | undefined> => {
    const result = await updateIdentifier({
      variables: {
        roverId: '1',
        identifier: {
          IdentifierId: updateEntity.id,
          IdentifierName: updateEntity.IdentifierName,
          SubSystem: updateEntity.SubSystem,
          Description: updateEntity.Description,
        },
      },
    });
    const success = result.data.updateIdentifier !== undefined;
    if (success) {
      return updateEntity.id;
    }
    return undefined;
  };

  const deleteIdentifierHandler = async (id: String): Promise<boolean | undefined> => {
    const result = await deleteIdentifier({
      variables: {
        roverId: '1',
        identifierId: id,
      },
    });

    const success = result.data.deleteIdentifier.success;

    if (success) {
      return success;
    }
    return undefined;
  };

  // get all query configuration
  const getAllConfig = {
    query: GET_ALL_IDENTIFIERS,
    getAllQueryName: 'getAllIdentifiers',
    onGetAllMapper,
    getAllQueryVariables: {
      roverId: '1',
    },
  };

  // rows prior to the edit buttons
  const rowConfigPre: GridColumns = [
    { field: 'IdentifierName', headerName: 'Identifier Name', width: 300, editable: true },
    { field: 'SubSystem', headerName: 'SubSystem', width: 150, editable: true },
    { field: 'Description', headerName: 'Description', width: 500, editable: true },
  ];

  // rows after the edit buttons
  const rowConfigPost: GridColumns = [];

  const rowConfig = {
    rowConfigPre,
    rowConfigPost,
  };

  const addRecordConfig = {
    defaultRecord: { IdentifierName: '', SubSystem: '', Description: ''},
    focusField: 'IdentifierName',
  };

  // what does a valid row looklike?
  const rowValidationHandler = (entity: Record<string, string>): boolean => {
    const valid = validator.validate(identifierSchema, entity);
    return valid;
  };

  return (
    <PortalWrapper title="Identifiers">
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <CrudTable
          getAllConfig={getAllConfig}
          addRecordConfig={addRecordConfig}
          entityName={'Identifier'}
          rowConfig={rowConfig}
          rowValidationHandler={rowValidationHandler}
          createHandler={createIdentifierHandler}
          updateHandler={updateIdentifierHandler}
          deleteHandler={deleteIdentifierHandler}
          sortModel={[{ field: 'IdentifierName', sort: 'asc' }]}
        /> 
      </Stack>
    </PortalWrapper>
  );
};

export default Identifiers;
