import { IntegrationBuilderProps, SelectorProps } from '../../types';

import { Typography, Stack } from '@mui/material';

import { CHAR_ARR_INTEGRATION } from '@lunar-outpost/zap/lib/types';
import IdentifierSelect from '../Identifiers/IdentifierSelect';

const SINGLE_RESOURCE_EDITOR = ({
  integration,
  updateIntegration,
  identifierOptions,
  refetchIdentifiers,
  isIdentifierPrefixEnabled,
}: IntegrationBuilderProps) => {
  const { resource } = integration as CHAR_ARR_INTEGRATION;

  const handleIdentifierSelect = ({ value }: SelectorProps) => {
    if (value !== null) {
      const newIntegration = { resource: { resourceName: value.id } };
      updateIntegration({ integration: newIntegration });
    }
  };

  const currentValue = identifierOptions.filter((value) => {
    return value.id === resource?.resourceName;
  })[0];

  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} sx={{ padding: 2, width: '100%' }}>
      <Typography sx={{ width: '100%' }} display="inline" variant="body1">
        Resource Name (Identifier)
      </Typography>
      <IdentifierSelect
        options={identifierOptions}
        value={currentValue}
        refetchIdentifiers={refetchIdentifiers}
        handleIdentifierSelect={handleIdentifierSelect}
        updateIntegration={updateIntegration}
        isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
      />
    </Stack>
  );
};

export default SINGLE_RESOURCE_EDITOR;
