import { useState, useEffect } from 'react';

import { ToggleButtonGroup, ToggleButton, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/material';

import { BIT_MASK_INTEGRATION, Resource } from '@lunar-outpost/zap/lib/types';

import { SelectorProps, IntegrationBuilderProps } from '../../types';
import DefaultIdentifierSelect from '../Identifiers/DefaultIdentifierSelect';

type BIT_MASK_PROPS = Omit<IntegrationBuilderProps, 'handlePrefixEnabledClick' | 'isIdentifierPrefixEnabled'>;

const BIT_MASK_EDITOR = ({ integration, updateIntegration, identifierOptions, refetchIdentifiers }: BIT_MASK_PROPS) => {
  const { resources } = integration as BIT_MASK_INTEGRATION;

  const byteList = [];
  for (let i = 0; i < resources.length / 8; i++) {
    byteList.push('');
  }

  const [activeByte, setActiveByte] = useState('0');

  useEffect(() => {
    // ensure that we count down the byte count if we are out of range
    if (Number(activeByte) * 8 >= resources.length) {
      setActiveByte((Number(activeByte) - 1).toString());
    }
  }, [activeByte, resources]); // Only re-subscribe if props.resources or props.activeByte changes

  const handleChange = (event: React.MouseEvent<HTMLElement>, newActiveByte: string) => {
    setActiveByte(newActiveByte);
  };

  const handleIdentifierSelect = (props: SelectorProps | null) => {
    if (!(props === null || props.value === null || props.index === undefined)) {
      const newIntegration = { resourceName: props.value.id };
      const newResources: Resource[] = JSON.parse(JSON.stringify(resources));
      newResources[props.index] = newIntegration;
      updateIntegration({ integration: { resources: newResources } });
    }
  };

  const getCurrentValue = (resourceName: string | undefined): { id: string; label: string } => {
    return identifierOptions.filter((value) => {
      return value.id === resourceName;
    })[0];
  };

  const indexBaseline = Number(activeByte) * 8;
  const indexOffets = [0, 1, 2, 3, 4, 5, 6, 7];

  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} sx={{ padding: 2 }}>
      <Typography color="text.secondary">Byte 0 is the first byte transmitted. b0 is the LSB of the bits in the register. </Typography>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Typography color="text.primary">Selected byte</Typography>
        <ToggleButtonGroup color="primary" value={activeByte} exclusive onChange={handleChange} aria-label="Platform">
          {byteList.map((elm, i) => {
            return (
              <ToggleButton key={`toggle-${i}`} value={`${i}`}>
                {i}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
      <>
        {indexOffets.map((indexOffset) => {
          return (
            <Stack key={`bit-${indexOffset}`} direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ width: '100%' }}>
              <Typography display="inline">{`b${indexOffset}`}</Typography>
              <Divider orientation="vertical" flexItem></Divider>
              <DefaultIdentifierSelect
                value={getCurrentValue(resources[indexBaseline + indexOffset].resourceName as string)}
                index={indexBaseline + indexOffset}
                options={identifierOptions}
                refetchIdentifiers={refetchIdentifiers}
                handleIdentifierSelect={handleIdentifierSelect}
              />
            </Stack>
          );
        })}
      </>
    </Stack>
  );
};

export default BIT_MASK_EDITOR;
