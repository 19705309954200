import { Stack, Typography, Box } from '@mui/material';

import { IDENTIFIER_INTEGRATION } from '@lunar-outpost/zap/lib/types';
import { IntegrationBuilderProps } from '../../types';
import ValueMapTable from '../VALUE_MAP/ValueMapTable';

type IdentifierTypeProps = Omit<IntegrationBuilderProps, 'identifierOptions' | 'refetchIdentifiers' | 'isIdentifierPrefixEnabled'>;

const IdentifierType = ({ updateIntegration, integration }: IdentifierTypeProps) => {
  const { resourcePrefixMap } = integration as IDENTIFIER_INTEGRATION;

  const updateResourcePrefixMap = (prefixMap: Record<string, string>) => {
    const newIntegration = JSON.parse(JSON.stringify(integration)) as IDENTIFIER_INTEGRATION;
    newIntegration.resourcePrefixMap = prefixMap;
    updateIntegration({ integration: newIntegration });
  };

  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} sx={{ padding: 2, width: '100%', height: '100%' }}>
      <Typography sx={{ width: 'inherit' }} display="inline" variant="body1">
        Resource Prefix
      </Typography>
      <Box sx={{ height: '100%', width: '100%' }}>
        <ValueMapTable pairs={resourcePrefixMap} updatePairs={updateResourcePrefixMap} />
      </Box>
    </Stack>
  );
};

export default IdentifierType;
