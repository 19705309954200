// import views
import Router from 'Routes';

import { Amplify } from 'aws-amplify';

import { Authenticator, ThemeProvider, Theme, useTheme} from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import { components } from 'amplify/auth/components';

import awsExports from 'aws-exports';
Amplify.configure(awsExports);


const StyleAuthenticator = () => {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: 'Lunar Auth Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: '#FFFFFF',
          },
          secondary: {
            value: tokens.colors.neutral['100'].value,
          },
        },
        font: {
          interactive: {
            value: tokens.colors.black.value,
          },
        },
        brand: {
          primary: {
            '10': '#008ec5',
            '80': '#009EDB',
            '90': '#4dbbe6',
            '100': '#80cfed',
          },
        },
      },
    },
  };
  return (

        <ThemeProvider theme={theme}>
          <Authenticator components={components} hideSignUp>
            {({ signOut, user }) => <Router signOut={signOut} user={user}/>}
          </Authenticator>
        </ThemeProvider>

  );
};

export default StyleAuthenticator;
