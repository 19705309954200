import { useState, useEffect } from 'react';

// import react-router stuff
import { Routes, Route, useNavigate } from 'react-router-dom';
import { messagesUrl } from 'utilities/navigation';

import { AuthEventData, AmplifyUser } from '@aws-amplify/ui';


// import Views
import Layout from 'Views/Portal/Layout';
import Messages from 'Views/Portal/Messages';
import Identifiers from 'Views/Portal/Identifiers'
import MessageDefintions from 'Views/Portal/MessageDefinitions';

import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';

import { darkTheme } from 'themes';

interface RouterProps {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
  user: AmplifyUser | undefined;
}

const Router = ({ signOut, user }: RouterProps) => {
  const [isDarkMode, setIsDarkMode] = useState(() => true);

  const navigate = useNavigate();


  useEffect(() => {
    const isRoot = (window.location.pathname) === '/';

    if (isRoot) {
      navigate(messagesUrl())
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        style={{
          width: '99vw',
          height: '100vh',
          backgroundColor: darkTheme.palette.background.default,
        }}
      >
        <Routes>
          <Route path="/portal" element={<Layout isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} signOut={signOut} user={user} />}>
            <Route path="messages">
            <Route index element={<Messages />} />
              <Route path="message-definitions">
                <Route path=":zapMessageId" element={<MessageDefintions />} />
              </Route>
            </Route>
            <Route path="identifiers" element={<Identifiers />} />
          </Route>
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default Router;
