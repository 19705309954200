// import global styles
import 'App.css';

// import react stuff
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import { Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import StyleAuthenticator from 'amplify/auth';
import awsExports from 'aws-exports';
import { LicenseInfo } from '@mui/x-license-pro';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, DefaultOptions } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const httpLink = createHttpLink({
  uri:
    process.env.REACT_APP_CLOUDFRONT_ENDPOINT === undefined
      ? `https://${window.location.hostname}/graphql`
      : `https://${process.env.REACT_APP_CLOUDFRONT_ENDPOINT}/graphql`,
  credentials: process.env.REACT_APP_CREDENTIAL_MODE,
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// TODO: Handle token expiration mid-session
const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.getJwtToken()}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

LicenseInfo.setLicenseKey('a885d22d28287e69c1a5c797ea31e5c9Tz01MTA0NSxFPTE2OTQ5OTc4NTAwMjEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

Amplify.configure(awsExports);

const App = () => {
  useEffect(() => {
    const currentHour = new Date().getHours();
    const mainContainer = document.getElementById('main-app-container');

    // select random background based on time of day
    if (mainContainer) {
      if (currentHour % 4 === 0) {
        mainContainer.classList.add('police-background');
      } else if (currentHour % 4 === 1) {
        mainContainer.classList.add('chimp-background');
      } else if (currentHour % 4 === 2) {
        mainContainer.classList.add('lama-background');
      } else if (currentHour % 4 === 3) {
        mainContainer.classList.add('normal-background');
      }
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <div className="App" id="main-app-container">
        <BrowserRouter>
          <Flex justifyContent="center" alignItems="center" height={'100vh'}>
            <StyleAuthenticator />
          </Flex>
        </BrowserRouter>
        <ToastContainer autoClose={1500} />
      </div>
    </ApolloProvider>
  );
};

export default App;
