import { Modal, Box, Typography, Button } from '@mui/material';

interface GenerateDefinitionsDialogProps {
  open: boolean;
  onClick: () => any;
  onClose: () => void;
}

const GenerateDefinitionsDialog = (props: GenerateDefinitionsDialogProps) => {
  return (
    <Modal open={props.open} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography sx={{ paddingBottom: '15px' }}>
          Please confirm you want to generate a new ZAP definitions file. This will create a new version. Only continue if you know what you are
          doing.
        </Typography>
        <Typography>Definitions that will be included:</Typography>
        <Box sx={{ paddingBottom: '15px' }} className="dialogSubtitle">
          <Typography color="secondary">Message Type: TELEMETRY, COMMANDS</Typography>
          <Typography color="secondary">Message Readiness: PRODUCTION, STAGING</Typography>
        </Box>
        <Button variant="outlined" color="success" onClick={props.onClick}>
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default GenerateDefinitionsDialog;
