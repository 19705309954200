import { FormControl, Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { IdentifierSelectorProps, ValueProps } from '../types';

interface SearchSelectorProps extends IdentifierSelectorProps {
  selectorLabel: string;
}

const SearchSelector = ({ selectorLabel, value, index, options, handleIdentifierSelect }: SearchSelectorProps) => {
  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        size="small"
        disablePortal
        fullWidth
        id="combo-box-demo"
        options={options}
        value={value}
        onChange={(event: SyntheticEvent, value: ValueProps | null) => handleIdentifierSelect({ value, index })}
        renderInput={(params) => <TextField {...params} label={selectorLabel} />}
      />
    </FormControl>
  );
};

export default SearchSelector;
