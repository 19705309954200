import { Button, ButtonGroup, FormControl, FormControlLabel, Typography } from '@mui/material';

interface IncrementorButtonsProps {
  counter: number;
  updateCount: (value: number) => void;
}

const IncrementorButtons = ({ counter, updateCount }: IncrementorButtonsProps) => {
  const handleIncrement = () => {
    const numCounter = Number(counter);
    if (!isNaN(numCounter)) {
      updateCount(numCounter + 1);
    }
  };

  const handleDecrement = () => {
    const numCounter = Number(counter);
    if (!isNaN(numCounter) && numCounter > 1) {
      updateCount(numCounter - 1);
    }
  };

  const isCounterEnabled = counter > 0;

  return (
    <FormControlLabel
      sx={{
        margin: '8px 0px 8px 0px',
        height: '100%',
        alignItems: 'start',
        paddingBottom: '12px',
        visibility: isCounterEnabled ? 'visible' : 'hidden',
      }}
      label={
        <Typography color={'rgba(255, 255, 255, 0.7)'} fontSize={'small'}>
          # of Bytes
        </Typography>
      }
      labelPlacement="top"
      control={
        <FormControl>
          <ButtonGroup size="small">
            <Button onClick={handleDecrement}>-</Button>
            <Button
              sx={{
                color: '#FFFFFF !important',
                border: '1px solid rgba(255, 255, 255, 0.5) !important',
              }}
              disabled
            >
              {counter}
            </Button>
            <Button onClick={handleIncrement}>+</Button>
          </ButtonGroup>
        </FormControl>
      }
    />
  );
};

export default IncrementorButtons;
