import { FormControlLabel, Stack, Switch } from '@mui/material';
import DefaultIdentifierSelect from '../DefaultIdentifierSelect';
import { ChangeEvent } from 'react';
import { IdentifierSelectorProps, UpdateIntegrationProps } from '../../../types';

interface IdentifierSelectProps extends IdentifierSelectorProps {
  refetchIdentifiers: () => void;
  updateIntegration: (props: UpdateIntegrationProps) => void;
  isIdentifierPrefixEnabled: boolean;
}

const IdentifierSelect = ({
  options,
  value,
  refetchIdentifiers,
  handleIdentifierSelect,
  updateIntegration,
  isIdentifierPrefixEnabled,
}: IdentifierSelectProps) => {
  const handleSwitchClick = (checked: boolean) => {
    updateIntegration({ prefixEnabled: checked });
  };

  return (
    <Stack direction="column" spacing={2} justifyContent="flex-start" alignItems="flex-start" sx={{ width: '100%', height: '100%' }}>
      <FormControlLabel
        label="Use Identifier Prefix"
        control={
          <Switch checked={isIdentifierPrefixEnabled} onChange={(event: ChangeEvent<HTMLInputElement>) => handleSwitchClick(event.target.checked)} />
        }
      />
      <DefaultIdentifierSelect
        options={options}
        value={value}
        handleIdentifierSelect={handleIdentifierSelect}
        refetchIdentifiers={refetchIdentifiers}
        selectorLabel={`Identifier${isIdentifierPrefixEnabled ? ' Suffix' : ''}`}
      />
    </Stack>
  );
};

export default IdentifierSelect;
