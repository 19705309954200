import { Stack, IconButton } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import SearchSelector from '../../../SearchSelect';
import { IdentifierSelectorProps } from '../../../types';

interface DefaultIdentifierSelectProps extends IdentifierSelectorProps {
  refetchIdentifiers: () => void;
  selectorLabel?: string;
}

const DefaultIdentifierSelect = (props: DefaultIdentifierSelectProps) => {
  const { selectorLabel, handleIdentifierSelect, refetchIdentifiers, options, value, index } = props;

  // TODO handle null ValueProps more gracefully?
  if (value === null) {
    return <Stack />;
  }

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} flexGrow={1} sx={{ width: '100%' }}>
      <SearchSelector
        value={value}
        index={index}
        handleIdentifierSelect={handleIdentifierSelect}
        options={options}
        selectorLabel={selectorLabel ?? 'Identifier'}
      />
      <IconButton aria-label="refresh" color="secondary" onClick={refetchIdentifiers}>
        <RefreshIcon />
      </IconButton>
    </Stack>
  );
};

export default DefaultIdentifierSelect;
