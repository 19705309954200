import { Stack, Box, Backdrop, CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';

interface PortalWrapperProps {
  title?: string;
  breadcrumbs?: JSX.Element;
  children: JSX.Element;
  loading?: boolean
}

const PortalWraper = ({ title, children, breadcrumbs, loading }: PortalWrapperProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      sx={{ height: '100%', marginRight: '16px', marginBottom: '16px' }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading !== undefined ? loading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          height: 20,
          paddingBottom: '40px',
        }}
      >
        {breadcrumbs !== undefined ? (
          breadcrumbs
        ) : (
          <Typography variant="h5" noWrap component="div">
            {title}
          </Typography>
        )}
      </Box>

      {children}
    </Stack>
  );
};

export default PortalWraper;
