import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { useTheme } from '@mui/material';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import LinkIcon from '@mui/icons-material/Link';

interface DataGridIconLinkProps {
  value: string;
  urlRenderer: (text: string) => string;
}
const DataGridIconLink = memo(({ value, urlRenderer }: DataGridIconLinkProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 26,
      }}
    >
      <Link style={{ color: theme.palette.secondary.main, textDecoration: 'none !important' }} to={urlRenderer(value)} target="_blank">
        <LinkIcon />
      </Link>
    </Box>
  );
});

export const generateDataGridIconLinkRenderer = (urlRenderer: (text: string) => string) => {
  const renderDataGridIconLink = ({ value }: GridRenderCellParams) => {
    return <DataGridIconLink value={value} urlRenderer={urlRenderer} />;
  };

  return renderDataGridIconLink;
};
