import { FILE_TYPES, IntegrationBuilderProps, SelectorProps } from '../../types';

import { Typography, Stack, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';

import { FILE_INTEGRATION } from '@lunar-outpost/zap/lib/types';
import DefaultIdentifierSelect from '../Identifiers/DefaultIdentifierSelect';

type FileResourceProps = Omit<IntegrationBuilderProps, 'handlePrefixEnabledClick' | 'isIdentifierPrefixEnabled'>;

const FILE_RESOURCE_EDITOR = ({ integration, updateIntegration, identifierOptions, refetchIdentifiers }: FileResourceProps) => {
  const { resource } = integration as FILE_INTEGRATION;

  const handleIdentifierSelect = ({ value }: SelectorProps) => {
    const newIntegration = { resource: { resourceName: value!.id, fileExtension: resource.fileExtension } };
    updateIntegration({ integration: newIntegration });
  };

  const handleFileExtensionSelect = (event: SelectChangeEvent) => {
    const fileExtension = event.target.value;
    const newIntegration = { resource: { resourceName: resource.resourceName, fileExtension } };
    updateIntegration({ integration: newIntegration });
  };

  const currentIdentifier = identifierOptions.filter((value) => {
    return value.id === resource.resourceName;
  })[0];

  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} sx={{ padding: 2 }}>
      <Typography sx={{ width: '100%' }} display="inline" variant="body1">
        Resource Name (Identifier)
      </Typography>
      <DefaultIdentifierSelect
        value={currentIdentifier}
        options={identifierOptions}
        refetchIdentifiers={refetchIdentifiers}
        handleIdentifierSelect={handleIdentifierSelect}
      />
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel>File Extension</InputLabel>
        <Select label="File Extension" value={resource.fileExtension} onChange={handleFileExtensionSelect}>
          {Object.values(FILE_TYPES).map((fileType) => {
            return <MenuItem value={fileType}>{fileType.toLowerCase()}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default FILE_RESOURCE_EDITOR;
