import { useState } from 'react';
import { Stack, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';

import { useMutation } from '@apollo/client';
import { CREATE_MESSAGE, DELETE_MESSAGE, GET_ALL_MESSAGES, UPDATE_MESSAGE, GENERATE_ZAP_MESSAGE_DEFINITION } from 'api/queries';

import PortalWrapper from 'Components/PortalWrapper';
import { generateDataGridIconLinkRenderer } from 'Components/DataGridComponents/DataGridIconLink';
import CrudTable from 'Components/CrudTable';
import { GridColumns } from '@mui/x-data-grid-pro';

import { messageDefinitionsUrlGenerator } from 'utilities/navigation';
import { messageSchema, validator } from 'utilities/schemas';

import { toast } from 'react-toastify';
import GenerateDefinitionsDialog from 'Components/GenerateDefinitionsDialog';

const Messages = () => {
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [updateMessage] = useMutation(UPDATE_MESSAGE);
  const [deleteMessage] = useMutation(DELETE_MESSAGE);

  const [generateZapMessageDefinition] = useMutation(GENERATE_ZAP_MESSAGE_DEFINITION);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [messageTypeFilter, setMessageTypeFilter] = useState('TELEMETRY');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newMessageTypeFilter: string) => {
    setMessageTypeFilter(newMessageTypeFilter);
  };

  // When we get all the records back, how do we map these to rows?
  const onGetAllMapper = (elem: Record<string, string>) => {
    const { ZapMessageId, __typename, ...restOfElem } = elem;
    return { ...restOfElem, id: elem.ZapMessageId, ZAPEdit: elem.ZapMessageId };
  };

  // handle new record creation
  const createMessageHandler = async (newEntity: Record<string, string>): Promise<string | undefined> => {
    const result = await createMessage({
      variables: {
        roverId: '1',
        zapMessage: {
          System: newEntity.System,
          SubSystem: newEntity.SubSystem,
          Priority: newEntity.Priority,
          MessageClass: newEntity.MessageClass,
          Type: newEntity.Type,
          SubType: newEntity.SubType,
          FriendlyName: newEntity.FriendlyName,
          MessageType: newEntity.MessageType,
          MessageReadiness: newEntity.MessageReadiness,
          Notes: newEntity.Notes,
        },
      },
    });
    const success = result.data.createMessage !== undefined;
    if (success) {
      return result.data.createMessage.ZapMessageId;
    }
    return undefined;
  };

  // handle update record
  const updateMessageHandler = async (updateEntity: Record<string, string>): Promise<string | undefined> => {
    const result = await updateMessage({
      variables: {
        roverId: '1',
        zapMessage: {
          ZapMessageId: updateEntity.id,
          System: updateEntity.System,
          SubSystem: updateEntity.SubSystem,
          Priority: updateEntity.Priority,
          MessageClass: updateEntity.MessageClass,
          Type: updateEntity.Type,
          SubType: updateEntity.SubType,
          FriendlyName: updateEntity.FriendlyName,
          MessageType: updateEntity.MessageType,
          MessageReadiness: updateEntity.MessageReadiness,
          Notes: updateEntity.Notes ? updateEntity.Notes : '',
        },
      },
    });
    const success = result.data.updateMessage !== undefined;
    if (success) {
      return updateEntity.id;
    }
    return undefined;
  };

  const deleteMessageHandler = async (id: String): Promise<boolean | undefined> => {
    const result = await deleteMessage({
      variables: {
        roverId: '1',
        zapMessageId: id,
      },
    });

    const success = result.data.deleteMessage.success;

    if (success) {
      return success;
    }
    return undefined;
  };
  // get all query configuration
  const getAllConfig = {
    query: GET_ALL_MESSAGES,
    getAllQueryName: 'getAllMessages',
    onGetAllMapper,
    getAllQueryVariables: {
      roverId: '1',
      messageType: messageTypeFilter,
    },
  };

  // rows prior to the edit buttons
  const rowConfigPre: GridColumns = [
    { field: 'System', type: 'singleSelect', valueOptions: ['FTFC', 'PAYLOAD_PC', 'OTHER'], headerName: 'System', width: 180, editable: true },
    { field: 'SubSystem', headerName: 'SubSystem', width: 150, editable: true },
    {
      field: 'Priority',
      type: 'singleSelect',
      valueOptions: ['CRITICAL', 'QUALITY_OF_LIFE', 'NICE_TO_HAVE', 'OTHER'],
      headerName: 'Priority',
      width: 150,
      editable: true,
    },
    {
      field: 'MessageClass',
      type: 'singleSelect',
      valueOptions: ['DR', 'AE', 'CS', 'OTHER'],
      headerName: 'Message Class',
      width: 150,
      editable: true,
    },
    { field: 'Type', headerName: 'Type', width: 80, editable: true },
    { field: 'SubType', headerName: 'SubType', width: 80, editable: true },
    { field: 'FriendlyName', headerName: 'Friendly Name', width: 150, editable: true },
    {
      field: 'MessageType',
      type: 'singleSelect',
      valueOptions: ['TELEMETRY', 'COMMANDS', 'INTERNAL'],
      headerName: 'Message Type',
      width: 150,
      editable: true,
    },
    {
      field: 'MessageReadiness',
      type: 'singleSelect',
      valueOptions: ['DEVELOPMENT', 'STAGING', 'PRODUCTION'],
      headerName: 'Message Readiness',
      width: 150,
      editable: true,
    },
    { field: 'Notes', headerName: 'Notes', width: 150, editable: true },
  ];

  // rows after the edit buttons
  const rowConfigPost: GridColumns = [
    { field: 'ZAPEdit', headerName: 'Definition Edit', width: 150, renderCell: generateDataGridIconLinkRenderer(messageDefinitionsUrlGenerator) },
  ];

  const rowConfig = {
    rowConfigPre,
    rowConfigPost,
  };

  const addRecordConfig = {
    defaultRecord: {
      System: '',
      SubSystem: '',
      MessageClass: '',
      Priority: '',
      Type: '',
      SubType: '',
      FriendlyName: '',
      MessageType: '',
      MessageReadiness: '',
      Notes: '',
    },
    focusField: 'System',
  };

  // what does a valid row looklike?
  const rowValidationHandler = (entity: Record<string, string>): boolean => {
    delete entity.ZAPEdit;
    const valid = validator.validate(messageSchema, entity);
    return valid;
  };

  const handleGenerateZapDefinitionClick = async () => {
    console.log('saving generation');
    const result = await generateZapMessageDefinition({
      variables: {
        roverId: '1',
        messageType: messageTypeFilter,
      },
    });

    const success = result.data.generateZapMessageDefinition.success;

    if (success) {
      toast.success('Generated ZAP Message Definition.');
    }
    handleClose();
  };

  return (
    <>
      <PortalWrapper title="Messages">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ width: '100%' }}>
            <ToggleButtonGroup color="primary" value={messageTypeFilter} exclusive onChange={handleChange} aria-label="Platform">
              <ToggleButton value="TELEMETRY">Telemetry</ToggleButton>
              <ToggleButton value="COMMANDS">Commands</ToggleButton>
              <ToggleButton value="INTERNAL">Internal</ToggleButton>
            </ToggleButtonGroup>
            <Button variant="outlined" color="success" onClick={handleOpen}>
              Generate ZAP Definitions
            </Button>
          </Stack>

          <CrudTable
            getAllConfig={getAllConfig}
            addRecordConfig={addRecordConfig}
            entityName={'Message'}
            rowConfig={rowConfig}
            rowValidationHandler={rowValidationHandler}
            createHandler={createMessageHandler}
            updateHandler={updateMessageHandler}
            deleteHandler={deleteMessageHandler}
            sortModel={[{ field: 'System', sort: 'asc' }]}
          />
        </Stack>
      </PortalWrapper>
      <GenerateDefinitionsDialog onClick={handleGenerateZapDefinitionClick} open={open} onClose={handleClose}></GenerateDefinitionsDialog>
    </>
  );
};

export default Messages;
