import { Stack, Box, Divider } from '@mui/material';

import { InputLabel, MenuItem, FormControl, TextField, Typography, IconButton } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import IncrementorButtons from 'Components/IncrementorButtons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import { Definition } from '../../types';

import { BIT_MASK_INTEGRATION, INTEGRATION_TYPES } from '@lunar-outpost/zap/lib/types';

interface ZAPWrapperProps {
  block: Definition;
  blockId: string;
  blockIndex: number;
  updateBlock: (blockId: string, newBlock: Definition) => void;
  handleBlockClick: (id: string) => void;
  deleteBlockHandler: (id: string) => void;
}

const ZAPWrapper = ({ blockId, block, blockIndex, updateBlock, handleBlockClick, deleteBlockHandler }: ZAPWrapperProps) => {
  const handleTypeSelection = (event: SelectChangeEvent) => {
    const newBlock = JSON.parse(JSON.stringify(block)) as Definition;
    const newZapType = event.target.value;
    newBlock.IntegrationType = newZapType;
    updateBlock(blockId, newBlock);
  };

  const handleLabelUpdate = (value: string) => {
    const newBlock = JSON.parse(JSON.stringify(block)) as Definition;
    newBlock.Label = value;
    updateBlock(blockId, newBlock);
  };

  const handleByteCountUpdate = (value: number) => {
    const newBlock = JSON.parse(JSON.stringify(block)) as Definition;
    newBlock.ByteCount = value;

    if (newBlock.IntegrationType === INTEGRATION_TYPES.BIT_MASK) {
      const integrationRef = newBlock.Integration as BIT_MASK_INTEGRATION;
      const resources = integrationRef.resources;
      if (newBlock.ByteCount > block.ByteCount) {
        for (let i = 0; i < 8; i++) {
          resources.push({ resourceName: '' });
        }
      } else {
        for (let i = 0; i < 8; i++) {
          resources.pop();
        }
      }
    }
    updateBlock(blockId, newBlock);
  };

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={0} sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
      <Box sx={{ width: '100%', padding: '0px 16px 8px 16px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography variant="body1">{blockIndex}</Typography>
          <Divider orientation="vertical" flexItem />
          <FormControl sx={{ minWidth: 250 }} size="small">
            <InputLabel>ZAP Type</InputLabel>
            <Select value={block.IntegrationType} label="ZAP Type" onChange={handleTypeSelection}>
              <MenuItem value={INTEGRATION_TYPES.CHAR_ARR}>CHAR_ARR</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.BOOLEAN}>BOOLEAN</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.FILE}>FILE</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.UNSIGNED_INTEGER}>UNSIGNED_INTEGER</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.SIGNED_INTEGER}>SIGNED_INTEGER</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.HALF_PREC_FLOAT}>HALF_PREC_FLOAT</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.SINGLE_PREC_FLOAT}>SINGLE_PREC_FLOAT</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.SINGLE_BYTE_FLOAT}>SINGLE_BYTE_FLOAT</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.VALUE_MAP}>VALUE_MAP</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.BIT_MASK}>BIT_MASK</MenuItem>
              <MenuItem value={INTEGRATION_TYPES.IDENTIFIER}>IDENTIFIER</MenuItem>
            </Select>
          </FormControl>
          <IncrementorButtons counter={block.ByteCount} updateCount={handleByteCountUpdate} />
          <TextField
            id="outlined-basic"
            label="Field Description"
            variant="outlined"
            size="small"
            required={false}
            value={block.Label}
            onChange={(e) => handleLabelUpdate(e.target.value)}
            sx={{ minWidth: 200 }}
          />
          <IconButton
            color="primary"
            component="label"
            onClick={() => {
              handleBlockClick(blockId);
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            color="error"
            component="label"
            onClick={() => {
              deleteBlockHandler(blockId);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ZAPWrapper;
