import { useState, useCallback, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { getValidatorErrors } from 'utilities/schemas';
import { isEqualsJson } from 'utilities/json';
import { DocumentNode, useLazyQuery } from '@apollo/client';

import { toast } from 'react-toastify';

import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridColumns,
  GridRowParams,
  GridCellParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  useGridApiRef,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridSortModel,
} from '@mui/x-data-grid-pro';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

const EditToolbarGenerator = (defaultRow: Record<string, string>, focusField: string, entityName: string) => {
  const EditToolbar = (props: EditToolbarProps) => {
    const { setRows, setRowModesModel } = props;

    const handleClick = async () => {
      const id = uuidv4();

      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          isNew: true,
          ...defaultRow,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: focusField },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} id="entry-button">
          Add {entityName}
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };
  return EditToolbar;
};

interface DataTableProps {
  entityName: string;
  createHandler: (newEntity: Record<string, string>) => Promise<string | undefined>;
  updateHandler: (updateEntity: Record<string, string>) => Promise<string | undefined>;
  deleteHandler: (id: string) => Promise<boolean | undefined>;
  getAllConfig: {
    query: DocumentNode;
    getAllQueryName: string;
    onGetAllMapper: (elem: Record<string, string>) => Record<string, string>;
    getAllQueryVariables: Record<string, string>;
  };
  rowConfig: {
    rowConfigPre: GridColumns;
    rowConfigPost: GridColumns;
  };
  addRecordConfig: {
    defaultRecord: Record<string, string>;
    focusField: string;
  };
  rowValidationHandler: (entity: Record<string, string>) => boolean;
  sortModel?: GridSortModel;
}

const DataTable = ({
  entityName,
  createHandler,
  updateHandler,
  deleteHandler,
  getAllConfig,
  rowConfig,
  addRecordConfig,
  rowValidationHandler,
  sortModel
}: DataTableProps) => {
  const [rows, setRows] = useState([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const apiRef = useGridApiRef();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [getAllRows] = useLazyQuery(getAllConfig.query, {
    onCompleted: (result) => {
      const newData = result[getAllConfig.getAllQueryName].map(getAllConfig.onGetAllMapper);
      setRows(newData);

      if (!firstLoad) {
        const newEntryButton = document.getElementById('entry-button');
        if (newEntryButton) {
          setTimeout(function () {
            newEntryButton.click();
          }, 500);
        }
      }
    },
    variables: getAllConfig.getAllQueryVariables,
  });

  useEffect(() => {
    getAllRows();
  }, [getAllRows]);

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCellKeyDown = (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>) => {
    if (event.key === 'Enter') {
      handleSaveClick(params.id)();
    }
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    const newRow = apiRef.current.unstable_getRowWithUpdatedValuesFromRowEditing(id);

    const { isNew, ...newMessage } = newRow;
    const valid = rowValidationHandler(newMessage);
    if (!valid) {
      toast.error(getValidatorErrors());
      return;
    }

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const success = await deleteHandler(id as string);
    if(success) {
      getAllRows();
      toast.success("Delete successful")
    } else {
      toast.error("Delete failed! Please contact system admin")
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row.id === id) as any;
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row.id !== id));
    }
  };

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      // if the rows are equal, then dont resave just ignore
      if (isEqualsJson(newRow, oldRow)) {
        return oldRow;
      }

      // Is this a newMessage (create) or existing (update)
      const { isNew, ...newMessage } = newRow;

      // deep copy JSON
      const savedNewRow = JSON.parse(JSON.stringify(newMessage));

      let success = false;
      let type = isNew === true ? 'create' : 'update';
      if (type === 'create') {
        const newId = await createHandler(newMessage);
        if (newId !== undefined) {
          success = true;
        }
      } else {
        const id = await updateHandler(newMessage);
        if (id !== undefined) {
          success = true;
        }
      }
      if (!success) {
        toast.error(`Could not ${type} ${entityName}!`);
        return oldRow;
      } else {
        toast.success(`${entityName} ${type}d!`);
      }

      getAllRows();
      setFirstLoad(false);
      return savedNewRow;
    },
    [createHandler, entityName, getAllRows, updateHandler]
  );

     const columns: GridColumns = [
    ...rowConfig.rowConfigPre,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
        ];
      },
    },
    ...rowConfig.rowConfigPost,
  ];

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          initialState= {{
            sorting: {
              sortModel
            },
          }}
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          loading={rows.length === 0}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbarGenerator(addRecordConfig.defaultRecord, addRecordConfig.focusField, entityName),
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pagination
          onCellKeyDown={handleCellKeyDown}
        />
      </div>
    </Box>
  );
};

export default DataTable;
