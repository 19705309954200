import { gql } from '@apollo/client';

// messages
export const CREATE_MESSAGE = gql`
  mutation CreateMessage($roverId: String!, $zapMessage: ZapMessageInput!) {
    createMessage(RoverId: $roverId, Message: $zapMessage) {
      ZapMessageId
      System
      SubSystem
      Priority
      MessageClass
      Type
      SubType
      FriendlyName
      Notes
    }
  }
`;

export const GET_MESSAGE = gql`
  query GetMessage($roverId: String!, $zapMessageId: String!) {
    getMessage(RoverId: $roverId, ZapMessageId: $zapMessageId) {
      ZapMessageId
      System
      SubSystem
      Priority
      MessageClass
      Type
      SubType
      FriendlyName
      Notes
    }
  }
`;

export const GET_ALL_MESSAGES = gql`
  query GetAllMessage($roverId: String!, $messageType: MessageType) {
    getAllMessages(RoverId: $roverId, MessageType: $messageType) {
      ZapMessageId
      System
      SubSystem
      Priority
      MessageClass
      Type
      SubType
      FriendlyName
      Notes
      MessageReadiness
      MessageType
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($roverId: String!, $zapMessage: ZapMessageUpdateInput!) {
    updateMessage(RoverId: $roverId, Message: $zapMessage) {
      success
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($roverId: String!, $zapMessageId: String!) {
    deleteMessage(RoverId: $roverId, ZapMessageId: $zapMessageId) {
      success
    }
  }
`;

// Identifiers
export const CREATE_IDENTIFIER = gql`
  mutation CreateIdentifier($roverId: String!, $identifier: IdentifierInput!) {
    createIdentifier(RoverId: $roverId, Identifier: $identifier) {
      IdentifierId
      IdentifierName
      SubSystem
      Description
    }
  }
`;

export const GET_IDENTIFIER = gql`
  query getIdentifier($roverId: String!, $identifierId: String!) {
    getIdentifier(RoverId: $roverId, IdentifierId: $identifierId) {
      IdentifierId
      IdentifierName
      SubSystem
      Description
    }
  }
`;

export const GET_ALL_IDENTIFIERS = gql`
  query GetAllIdentifiers($roverId: String!) {
    getAllIdentifiers(RoverId: $roverId) {
      IdentifierId
      IdentifierName
      SubSystem
      Description
    }
  }
`;

export const UPDATE_IDENTIFIER = gql`
  mutation UpdateIdentifier($roverId: String!, $identifier: IdentifierUpdateInput!) {
    updateIdentifier(RoverId: $roverId, Identifier: $identifier) {
      success
    }
  }
`;

export const DELETE_IDENTIFIER = gql`
  mutation DeleteIdentifier($roverId: String!, $identifierId: String!) {
    deleteIdentifier(RoverId: $roverId, IdentifierId: $identifierId) {
      success
    }
  }
`;

export const GET_ZAP_DEFINITION = gql`
  query GetZapMessageDefinition($roverId: String!, $zapMessageId: String!) {
    getZapMessageDefinition(RoverId: $roverId, ZapMessageId: $zapMessageId) {
      ZapMessageId
      Definition {
        ByteCount
        IntegrationType
        Integration
        UseResourcePrefix
      }
      Layout {
        w
        h
        x
        y
        i
        minW
        minH
        maxH
        moved
        static
      }
    }
  }
`;

export const GET_ALL_ZAP_DEFINITION = gql`
  query GetAllZapMessageDefinitions($roverId: String!) {
    getAllZapMessageDefinitions(RoverId: $roverId) {
      ZapMessageId
      Definition {
        ByteCount
        IntegrationType
        Integration
      }
      Layout {
        w
        h
        x
        y
        i
        minW
        minH
        maxH
        moved
        static
      }
    }
  }
`;

export const UPDATE_ZAP_DEFINITION = gql`
  mutation UpdateZapMessageDefinition($roverId: String!, $zapMessageDefinition: ZapMessageDefinitionUpdateInput!) {
    updateZapMessageDefinition(RoverId: $roverId, ZapMessageDefinition: $zapMessageDefinition) {
      success
    }
  }
`;

export const GENERATE_ZAP_MESSAGE_DEFINITION = gql`
  mutation GenerateZapMessageDefinition($roverId: String!, $messageType: MessageType!) {
    generateZapMessageDefinition(RoverId: $roverId, MessageType: $messageType) {
      success
    }
  }
`;
