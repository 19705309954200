import {  Stack, Button } from '@mui/material';

import { toast } from 'react-toastify';

interface ButtonHeaderProps {
  addNewItem: () => void;
  saveMessageDetails: () => void;
  resetData: () => void;
  blocksChanged: boolean;
}

const ButtonHeader = ({ addNewItem, saveMessageDetails, resetData, blocksChanged }: ButtonHeaderProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} sx={{ width: '100%', paddingRight: '20px' }}>
      <Button onClick={addNewItem} variant="outlined" color="secondary">
        ADD ITEM
      </Button>
      {blocksChanged ? (
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Button
            onClick={() => {
              toast.info('Discarding local changes to message');
              resetData();
            }}
            variant="outlined"
            color="error"
          >
            DISCARD CHANGES
          </Button>
          <Button onClick={saveMessageDetails} variant="outlined" color="success">
            SAVE CHANGES
          </Button>
        </Stack>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default ButtonHeader;
