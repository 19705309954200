import { IntegrationBuilderProps, SelectorProps } from '../../types';

import { Typography, Stack, Box } from '@mui/material';
import ValueMapTable from './ValueMapTable';

import { VALUE_MAP_INTEGRATION } from '@lunar-outpost/zap/lib/types';
import IdentifierSelect from '../Identifiers/IdentifierSelect';

const VALUE_MAP = ({ integration, updateIntegration, identifierOptions, refetchIdentifiers, isIdentifierPrefixEnabled }: IntegrationBuilderProps) => {
  const { resource, map } = integration as VALUE_MAP_INTEGRATION;

  const handleIdentifierSelect = ({ value }: SelectorProps) => {
    const copiedIntegration = JSON.parse(JSON.stringify(integration));
    copiedIntegration.resource.resourceName = value!.id;
    updateIntegration({ integration: copiedIntegration });
  };

  const updatePairs = (pairs: Record<string, string>) => {
    const copiedIntegration = JSON.parse(JSON.stringify(integration));
    copiedIntegration.map = pairs;
    updateIntegration({ integration: copiedIntegration });
  };

  const currentValue = identifierOptions.filter((value) => {
    return value.id === resource?.resourceName;
  })[0];

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      sx={{ padding: 2, height: '100%', marginTop: '8px !important' }}
    >
      <Typography sx={{ width: '100%' }} display="inline" variant="body1">
        Resource Name (Identifier)
      </Typography>
      <Box sx={{ maxHeight: '25%', width: '100%' }}>
        <IdentifierSelect
          value={currentValue}
          options={identifierOptions}
          refetchIdentifiers={refetchIdentifiers}
          handleIdentifierSelect={handleIdentifierSelect}
          updateIntegration={updateIntegration}
          isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
        />
      </Box>
      <Typography sx={{ width: '100%' }} display="inline" variant="body1">
        Value Map
      </Typography>
      <Box sx={{ width: '100%', height: '100%' }}>
        <ValueMapTable pairs={map} updatePairs={updatePairs} />
      </Box>
    </Stack>
  );
};

export default VALUE_MAP;
