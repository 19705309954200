import { View, Image, Flex } from '@aws-amplify/ui-react';
import logo from 'assets/logos/LunarOutpost_Horizontal.png';
import ZebchatGif from 'assets/logos/lo_zebchat_gif.gif'

export const components = {
  Header() {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        style={{
          backgroundColor: '#000000D3',
          padding: '30px',
        }}
      >
        <View textAlign="center">
          <Image alt="LO logo" src={logo} objectFit="initial" objectPosition="50% 50%" width={'100%'} />
          <Image alt="Zebchat logo" src={ZebchatGif} objectFit="initial" objectPosition="50% 50%" width={'50%'} />
        </View>
      </Flex>
    );
  },
};
