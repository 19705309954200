import { IntegrationBuilderProps, SelectorProps } from '../../types';

import { Box, TextField, Typography } from '@mui/material';

import { Stack } from '@mui/material';
import { SINGLE_BYTE_FLOAT_INTEGRATION } from '@lunar-outpost/zap/lib/types';
import IdentifierSelect from '../Identifiers/IdentifierSelect';

const SINGLE_BYTE_FLOAT = ({
  integration,
  updateIntegration,
  identifierOptions,
  refetchIdentifiers,
  isIdentifierPrefixEnabled,
}: IntegrationBuilderProps) => {
  const { resource, offset } = integration as SINGLE_BYTE_FLOAT_INTEGRATION;

  const handleIdentifierSelect = (props: SelectorProps | null) => {
    if (props !== null) {
      const { value } = props;
      const copiedIntegration = JSON.parse(JSON.stringify(integration));
      copiedIntegration.resource.resourceName = value!.id;
      updateIntegration({ integration: copiedIntegration });
    }
  };

  const handleOffsetSelect = (value: string) => {
    const copiedIntegration = JSON.parse(JSON.stringify(integration));
    const offsetNum = Number(value);
    copiedIntegration.offset = offsetNum;
    console.log(copiedIntegration);
    updateIntegration({ integration: copiedIntegration });
  };

  const currentValue = identifierOptions.filter((value) => {
    return value.id === resource?.resourceName;
  })[0];

  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} sx={{ padding: 2, width: '100%' }}>
      <Typography sx={{ width: 'inherit' }} display="inline" variant="body1">
        Resource Name (Identifier)
      </Typography>
      <Box sx={{ width: 'inherit' }}>
        <IdentifierSelect
          value={currentValue}
          options={identifierOptions}
          refetchIdentifiers={refetchIdentifiers}
          handleIdentifierSelect={handleIdentifierSelect}
          updateIntegration={updateIntegration}
          isIdentifierPrefixEnabled={isIdentifierPrefixEnabled}
        />
      </Box>
      <Typography sx={{ width: 'inherit' }} display="inline" variant="body1">
        Offset
      </Typography>
      <TextField
        size="small"
        type="number"
        fullWidth
        id="outlined-basic"
        label="Offset"
        variant="outlined"
        value={offset}
        onChange={(e) => handleOffsetSelect(e.target.value)}
      />
    </Stack>
  );
};

export default SINGLE_BYTE_FLOAT;
