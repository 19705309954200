import { useState } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { AmplifyUser, AuthEventData } from '@aws-amplify/ui';

import DrawerHeader from './DrawerHeader';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

interface LayoutProps {
  signOut?: (data?: AuthEventData | undefined) => void;
  user: AmplifyUser | undefined;
  isDarkMode: boolean;
  setIsDarkMode: (selection: boolean) => void;
}

const Layout = ({ signOut, user, isDarkMode, setIsDarkMode }: LayoutProps) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Topbar signOut={signOut} user={user} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} open={open} handleOpen={handleDrawerOpen} />
      <Sidebar open={open} handleClose={handleDrawerClose} />
      <Box component="div" sx={{ width: '100% !important', margin: '24px' }}>
        <DrawerHeader />
        <Box
          sx={{
            height: 'calc(100% - 64px)',
            width: 'calc(100%)'
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
